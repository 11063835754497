<template>
  <div>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="6">
        <div>
          <Button icon="md-add" type="primary" v-if="jurisdictionList.indexOf('admin-notice-add') !== -1" @click="addNotice('add')">新增</Button>
        </div>
      </Col>
    </Row>
    <Table border :columns="platformColumns" :data="data6">
      <template slot-scope="{ row, index }" slot="operation">
        <Button  type="info" ghost size="small" style="margin-right: 5px" @click="toGive(row.id)">发布</Button>
        <Button type="primary" size="small" style="margin-right: 5px" @click="show(index,'edit',row.id)">编辑</Button>
        <Button type="error" size="small" @click="removeNotice(row.id)">删除</Button>
      </template>
      <template slot-scope="{index} " slot="idx">
        <div>{{index + 1}}</div>
      </template>
    </Table>
    <br/>
    <Page :total="total"
          :current="noticePrams.page"
          style="text-align:right"
          show-elevator
          show-total @on-change="pageChange"
          :page-size="noticePrams.limit"/>
    <Modal
        :title="formTitle"
        v-model="showModel"
        width="600"
        :mask-closable="false"
        :footer-hide="true"
        class-name="vertical-center-modal">
      <Form :model="formItem" ref="formItem" :label-width="120" style="width: 500px">
        <FormItem label="版本号">
          <Input v-model="formItem.version_code" placeholder="请输入版本号"></Input>
        </FormItem>
        <FormItem label="更新内容">
          <Input v-model="formItem.content" type="textarea" :autosize="{minRows: 2,maxRows: 5}"
                 placeholder="请输入更新内容"></Input>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleSubmit">确认</Button>
          <Button @click="handleReset" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>
<script>
import {addMaintain, addRelease, delMaintain, editMaintain, getMintain, maintainList} from '../../api/platform'
import moment from "moment";

export default {
  data() {
    return {
      platformColumns: [
        {
          title: '#',
          slot: 'idx'
        },
        {
          title: '版本号',
          key: 'version_code',
        },
        {
          title: '版本内容',
          key: 'content'
        },
        {
          title: '更新时间',
          key: 'created_at'
        },
        {
          title: '操作',
          slot: 'operation'
        }
      ],
      data6: [],
      showModel: false,
      formItem: {
        version_code: '',
        content: '',
        id: '',
      },
      ruleValidate: {
        title: [
          { required: true, message: '请输入公告标题', trigger: 'blur' },
          { type: 'string', min: 3,max:12, message: '公告标题不得少于三个字符超过十二个字符', trigger: 'blur' }
        ],
        synopsis: [
          { required: true, message: '请输入公告详情', trigger: 'blur' }
        ],
        // store_id: [
        //   { required: false, message: '请选择公告平台', trigger: 'change' }
        // ],
        user_type: [
          { required: true, message: '请选择公告用户类型',trigger: 'change' },
        ],
        notice_type: [
          { required: true, message: '请选择公告类型', trigger: 'change' }
        ]
      },
      formTitle: '新增版本信息',
      noticePrams: {
        limit: 10,
        page: 1,
        title:'',
        created_at:''
      },
      noticeDetail: {},
      unList:[],
      total:0,
      jurisdictionList:[]
    }
  },
  created() {
    this.getList()
    this.jurisdictionList = this.$store.state.auth
  },
  mounted(){

  },
  methods: {
    getList() {
      maintainList(this.noticePrams).then(res => {
        if (res.code === 200) {
          this.noticeDetail = res.data
          this.data6 = this.noticeDetail.data
          this.total = res.data.total
        }
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    pageChange(index) {
      this.noticePrams.page = index
      this.getList()
    },
    searchList(){
      this.noticePrams.created_at = moment(this.noticePrams.created_at).format('YYYY-MM-DD')
      if(this.noticePrams.created_at == 'Invalid date'){
        this.noticePrams.created_at = ''
      }
      this.getList()
    },
    handleSubmit () {
      if(this.formItem.id){
        editMaintain(this.formItem).then(res => {
          if (res.code === 200) {
            this.$Message.success(res.msg)
            this.getList()
            this.reset()
            this.showModel=false
          }
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      }else {
        addMaintain(this.formItem).then(res => {
          if (res.code === 200) {
            this.$Message.success(res.msg)
            this.getList()
            this.reset()
            this.showModel=false
          }
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      }

    },
    handleReset () {

    },
    cancel() {
      this.reset()
    },
    show(index, type, id) {
      if (type === 'edit') {
        this.formTitle = '编辑版本信息'
      }
      getMintain({id: id}).then(res => {
        if (res.code === 200) {
          this.formItem.version_code = res.data.version_code
          this.formItem.content = res.data.content
          this.formItem.id = res.data.id
        }
      }).catch(res => {
        this.$Message.error(res.msg)
      })
      this.showModel = true
    },
    toGive(id){
      console.log(id)
      this.$Modal.confirm({
        title: '发布提示',
        content: '请问您是否确认发布此版本！！！',
        onOk: () => {
          addRelease({id:id}).then(res=>{
            this.getList()
            this.$Message.success(res.msg)
          }).catch(res=>{
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    removeNotice(id) {
      this.$Modal.confirm({
        title: '删除提示',
        content: '请问您是否确认删除此版本，删除后无法恢复！！！',
        onOk: () => {
          delMaintain({id:id}).then(res=>{
            this.getList()
            this.$Message.success(res.msg)
          }).catch(res=>{
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    addNotice(type) {
      if (type === 'add') {
        this.formTitle = '新增版本信息'
      }
      this.reset()
      this.showModel = true
    },
    reset(){
      this.formItem.version_code = ''
      this.formItem.content = ''
      this.formItem.id = ''
    }
  }
}
</script>
<style>
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-modal .ivu-modal {
  top: 0;
}

.flex-style {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.modal-form {

}

.form-label {
  padding-right: 12px;
  width: 120px;
  text-align: right;
  font-size: 14px;
  color: #515a6e;
}
</style>
